import type * as types from './resources.types';

import * as sort from 'utils/sort';

const normalizeLatestDoc = (
  doc: types.ResourceChildLatestDocFromResponseV2
): types.NormalizedDocumentShelfLatestDoc => {
  return {
    docId: doc.doc_id,
    isLatest: doc.is_latest,
    offShelfAt: doc.off_shelf_at,
    onShelfAt: doc.on_shelf_at,
    sourceReleaseId: doc.source_release_id,
    title: doc.title
  };
};

export const normalizeDocumentShelf = (
  documentShelf: types.DocumentShelfFromResponseV2
): types.NormalizedDocumentShelf => {
  return {
    children:
      documentShelf.children
        ?.map(normalizeDocumentShelf)
        ?.sort(sort.naturalSortWithRomanNumerals) ?? null,
    childrenIds: documentShelf.children_ids,
    id: documentShelf.id,
    latestDoc: documentShelf.latest_doc ? normalizeLatestDoc(documentShelf.latest_doc) : undefined,
    name: documentShelf.name,
    parentId: documentShelf.parent_id,
    maxAmountOfChildren: documentShelf.children?.length || documentShelf.children_ids.length
  };
};

export const normalizeResourceHierarchyV2 = (
  response: types.ResourceHierarchyResponseV2
): types.NormalizedDocumentShelf => {
  return normalizeDocumentShelf(response.resource_hierarchy);
};

export const getShelfById = ({
  id,
  currentShelf
}: {
  id: types.NormalizedDocumentShelf['id'];
  currentShelf: types.NormalizedDocumentShelf | null;
}): types.NormalizedDocumentShelf | null => {
  if (currentShelf?.id === id) {
    return currentShelf;
  }
  for (const child of currentShelf?.children ?? []) {
    if (child?.id === id) {
      return child;
    }
    const leaf = getShelfById({ currentShelf: child, id });
    if (leaf) {
      return leaf;
    }
  }

  return null;
};

export const updateShelves = ({
  shelves,
  updatedShelf
}: {
  shelves: types.NormalizedDocumentShelf | null;
  updatedShelf: Partial<types.NormalizedDocumentShelf>;
}): types.NormalizedDocumentShelf => {
  if (!shelves) {
    return updatedShelf as types.NormalizedDocumentShelf;
  }

  if (!updatedShelf.parentId) {
    const shelfChildren = shelves.children || [];
    const shelfIndex = shelfChildren.findIndex(shelf => shelf.id === updatedShelf.id);
    if (shelfIndex > -1) {
      shelfChildren[shelfIndex] = { ...shelfChildren[shelfIndex], ...updatedShelf };
    } else {
      shelfChildren.push(updatedShelf as types.NormalizedDocumentShelf);
    }
    return {
      ...shelves,
      children: shelfChildren
    };
  }

  if (updatedShelf?.id === shelves?.id) {
    return { ...shelves, ...updatedShelf };
  }

  return {
    ...shelves,
    children:
      shelves?.children?.map(childShelf => updateShelves({ shelves: childShelf, updatedShelf })) ??
      null
  };
};

export const getActiveShelfId = (breadcrumbs: types.Breadcrumb[]): number | null => {
  if (breadcrumbs.length) {
    return breadcrumbs[breadcrumbs.length - 1].shelf_id;
  }

  return null;
};

export const getActiveDocId = (breadcrumbs: types.Breadcrumb[]): number | null => {
  if (breadcrumbs.length) {
    return breadcrumbs[breadcrumbs.length - 1].latest_doc_id;
  }

  return null;
};

const getBreadcrumb = (shelf: types.NormalizedDocumentShelf): types.Breadcrumb => ({
  shelf_id: shelf.id as number,
  latest_doc_id: shelf.latestDoc?.docId as number,
  title: shelf.latestDoc?.title as string
});
export const getBreadcrumbsById = ({
  shelfId,
  shelves
}: {
  shelfId: number;
  shelves: types.NormalizedDocumentShelf | null;
}): types.Breadcrumb[] | null => {
  if (shelves?.id === shelfId) {
    return [getBreadcrumb(shelves)];
  }

  for (const child of shelves?.children ?? []) {
    if (child?.id === shelfId) {
      return [getBreadcrumb(child)];
    }
    const leaf = getBreadcrumbsById({ shelves: child, shelfId });
    if (leaf) {
      return [getBreadcrumb(child), ...leaf];
    }
  }

  return null;
};

export const getVisibleShelves = (
  parentShelf: types.NormalizedDocumentShelf,
  selectedShelfIds: number[]
): [number, types.Resources2][] => {
  if (!parentShelf) {
    return [];
  }

  const nextShelf = parentShelf?.children?.find(shelf => {
    if (!shelf.childrenIds?.length && !shelf.children?.length) {
      return false;
    }

    return selectedShelfIds.includes(shelf.id as number);
  });

  const currentShelf: [number, types.Resources2][] = parentShelf.id
    ? [
        [
          parentShelf.id,
          {
            items: parentShelf?.children ?? [],
            maxAmountOfChildren: parentShelf?.maxAmountOfChildren || 0
          }
        ]
      ]
    : [];

  return [...currentShelf, ...(nextShelf ? getVisibleShelves(nextShelf, selectedShelfIds) : [])];
};
