import { SearchButton } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import lodash from 'lodash';
import { formatQueryStringFromFilterParams } from '../../PrimaryFilter.helpers';
import { usePrimaryFilterData, usePrimaryFilterReduxActions } from '../../hooks';
import { convertToPrimaryFilterToAdvancedSearch } from './FooterButtons.helpers';
import queryString from 'utils/query-string';
import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';
import { SEARCH_TYPES } from 'constants/SearchTypes';

import { useLocation, useNavigate } from 'react-router-dom';

const FooterButtons = ({ searchQuery, currentView, onSubmit }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { reduxState, formattedData } = usePrimaryFilterData();

  const reduxActions = usePrimaryFilterReduxActions(reduxState);

  const handleConvertToAdvancedSearch = () => {
    convertToPrimaryFilterToAdvancedSearch({
      reduxState,
      reduxActions,
      navigate,
      location
    });
  };

  const handleAlertPreview = () => {
    onSubmit(false);
    reduxActions.showPreviewAlert();
  };

  const getUrlParams = () => {
    return formatQueryStringFromFilterParams({
      filterParams: reduxState.filterParams,
      location,
      searchQuery,
      currentView,
      limit: reduxState.actionBarFilterValues[ACTION_BAR_FILTER_KEY.ROWS_COUNT]
    });
  };

  const handleAlertUpdate = async () => {
    const alertId = reduxState.current_view.editAlertId;
    const searchArgs = queryString.parse(getUrlParams());

    onSubmit(false);

    await reduxActions.updateAlert(alertId, {
      search_args: searchArgs,
      name: currentView.alertName
    });

    reduxActions.addEditAlert(alertId, currentView.alertName);
    reduxActions.addViewAlert(alertId, currentView.alertName);
    reduxActions.showSaveAlertSuccessModal(currentView.alertName);
  };

  return (
    <>
      <Button type={BUTTON_TYPES.LINK} onClick={handleConvertToAdvancedSearch}>
        Convert to Advanced Search
      </Button>
      {formattedData.isAlertEditMode && reduxState.current_view.editAlertId ? (
        <>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            onClick={handleAlertPreview}
            isDisabled={!lodash.isEmpty(formattedData.filterErrors)}
          >
            Preview
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleAlertUpdate}
            isDisabled={!lodash.isEmpty(formattedData.filterErrors)}
          >
            Update This Alert
          </Button>
        </>
      ) : (
        <SearchButton
          type={BUTTON_TYPES.PRIMARY}
          onClick={() => onSubmit(true)}
          disabled={!lodash.isEmpty(formattedData.filterErrors)}
          text="Search"
          queryParams={getUrlParams()}
          searchType={SEARCH_TYPES.BASIC_SEARCH}
        />
      )}
    </>
  );
};

export default FooterButtons;
