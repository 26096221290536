export const nodeEnv = import.meta.env.NODE_ENV;
export const apiUrl = import.meta.env.VITE_JURISPECT_API_URL;
export const apiKey = import.meta.env.VITE_API_KEY;
export const appUrl = import.meta.env.VITE_APPLICATION_URL;
export const agencyLandingPageUrl = import.meta.env.VITE_AGENCY_LANDING_PAGE_URL;
export const appAdminUrl = import.meta.env.VITE_ADMIN_APPLICATION_URL;
export const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;
export const linkedInLoginRedirectUrl = import.meta.env.VITE_LINKEDIN_LOGIN_REDIRECT_URL;
export const linkedInActivateRedirectUrl = import.meta.env.VITE_LINKEDIN_ACTIVATE_REDIRECT_URL;
export const linkedInClientId = import.meta.env.VITE_LINKEDIN_CLIENT_ID;
export const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
export const googleLoginRedirectUrl = import.meta.env.VITE_GOOGLE_LOGIN_REDIRECT_URL;
export const googleActivateRedirectUrl = import.meta.env.VITE_GOOGLE_ACTIVATE_REDIRECT_URL;
export const onboardingTooltipEnabled = import.meta.env.VITE_ONBOARDING_TOOLTIP_ENABLED;
export const rssEnabled = import.meta.env.VITE_RSS_ENABLED;
export const rssUrl = import.meta.env.VITE_RSS_URL;
export const activateVideo = import.meta.env.VITE_ACTIVATE_PAGE_VIDEO;
export const notificationsEnabled = import.meta.env.VITE_NOTIFICATIONS_ENABLED;
export const workflowEnabled = import.meta.env.VITE_WORKFLOW_ENABLED;
export const enforcementExplorerEnabled = import.meta.env.VITE_ENFORCEMENT_EXPLORER_ENABLED;
export const googleSearchAPIKey = import.meta.env.VITE_GOOGLE_SEARCH_API_KEY;
export const googleSearchCXId = import.meta.env.VITE_GOOGLE_SEARCH_CX_ID;
export const diffingEnabled = import.meta.env.VITE_DIFFING_ENABLED;
export const tasksPageV2 = import.meta.env.VITE_TASKS_PAGE_V2;
export const tasksPageV2Reassign = import.meta.env.VITE_TASKS_PAGE_V2_REASSIGN;
export const newsSourceS3BucketUrl = import.meta.env.VITE_NEWSSOURCE_S3_BUCKET_URL;
export const activationUrl = import.meta.env.VITE_ACCOUNT_ACTIVATION_URL;
export const supportIframeURL =
  import.meta.env.VITE_SUPPORT_IFRAME_URL || 'https://www.compliance.ai/product-guides-iframe';
export const isAdminPageEnabled = import.meta.env.VITE_ADMIN_PAGE_ENABLED || false;
export const defaultCFRID = import.meta.env.VITE_DEFAULT_CFR_ID || 6441656;
export const mixPanelEnabled = import.meta.env.VITE_MIX_PANEL_ENABLED || false;
export const regulationsTabEnabled = import.meta.env.VITE_REGULATIONS_TAB_ENABLED || false;
export const newResourcesMocked = import.meta.env.VITE_NEW_RESOURCES_MOCKED || null;
export const userPoolId = import.meta.env.VITE_USER_POOL_ID || null;
export const clientId = import.meta.env.VITE_COGNITO_CLIENT_ID || null;
export const cognitoDomain = import.meta.env.VITE_COGNITO_DOMAIN || null;
export const bulkLabelImportEnabled = import.meta.env.VITE_BULK_LABEL_IMPORT_ENABLED || false;
export const downloadAlertReportEnabled =
  import.meta.env.VITE_DOWNLOAD_ALERT_REPORT_ENABLED || false;
export const trainingCenterUrl = import.meta.env.VITE_TRAINING_CENTER_URL;
export const orgRelatedDocsEnabled = import.meta.env.VITE_ORG_RELATED_DOCS_ENABLED || false;
export const googleAnalyticsCode = import.meta.env.VITE_GOOGLE_ANALYTICS_CODE || null;
export const googleTagManagerCode = import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE || null;
export const shouldMockDocPermissions = import.meta.env.VITE_MOCK_DOC_PERMISSIONS || false;
export const shouldMockFilterGroupsResponse =
  import.meta.env.VITE_MOCK_FILTER_GROUPS_RESPONSE || false;
export const shouldShowRegulationsOverTimeChart =
  import.meta.env.VITE_SHOW_REGULATIONS_OVER_TIME_CHART || false;

export const shouldShowMoveLabelsButton =
  import.meta.env.VITE_SHOULD_SHOW_MOVE_LABELS_BUTTON || false;
export const shouldShowObligationsLink = import.meta.env.VITE_SHOULD_OBLIGATIONS_LINK || false;
export const shouldShowBucketObligations = import.meta.env.VITE_SHOW_BUCKET_OBLIGATIONS || false;
export const shouldShowEitlStatus = import.meta.env.VITE_SHOULD_SHOW_EITL_STATUS || false;
export const shouldShowBucketWorkflows = import.meta.env.VITE_SHOULD_SHOW_BUCKET_WORKFLOWS || false;
export const shouldShowBucketDocuments = import.meta.env.VITE_SHOULD_SHOW_BUCKET_DOCUMENTS || false;
export const shouldShowNewSearch = Boolean(import.meta.env.VITE_SHOULD_SHOW_NEW_SEARCH) || false;
export const shouldShowTaskDocumentView =
  Boolean(import.meta.env.VITE_SHOULD_SHOW_TASK_DOCUMENT_VIEW) || false;
export const shouldFetchNewOrgRequest =
  Boolean(import.meta.env.VITE_IMPROVED_ORG_REQUESTS) || false;
export const shouldDisplayExtendedAutoSummary =
  Boolean(import.meta.env.VITE_DISPLAY_EXTENDED_AUTO_SUMMARY) || false;
